const arrScripts = [
    '/static/slick.min',
    '/static/switch.min',
    '/static/kendo.all.min',
    '/static/kendo.timezones.min',
    '/static/daterangepicker',
    '/static/picker.date',
    '/static/picker.time',
    '/static/fab.min',
    '/static/uniform.min',
    '/static/select2.min',
    '/static/select2fix',
    '/static/bootstrap_multiselect',
    '/static/summernote.min',
    '/static/pace.min',
    '/static/ion_rangeslider.min',
    '/static/typeahead.bundle.min',
    '/static/canvasjs.min',
];

async function getManifest() {
    const response = await fetch(`/static/assets.json?cb=${new Date().getTime()}`, {
        headers: {
            'Cache-Control': 'no-cache',
        },
    });
    return await response.json();
}

function filterManifest(arrManifestScripts) {
    const strJQueryScript = arrManifestScripts.find((strScript) => strScript.startsWith('/static/jquery.min'));
    const strPickerScript = arrManifestScripts.find((strScript) => strScript.startsWith('/static/picker') && !strScript.includes('date') && !strScript.includes('time'));
    const arrFilteredScripts = arrManifestScripts.filter((strManifestScript) => arrScripts.some((strScript) => strManifestScript.startsWith(strScript)));
    return [
        strJQueryScript,
        '/static/moment.min.js',
        strPickerScript,
        ...arrFilteredScripts,
        window.chunks[window.bundle].js,
    ];
}

function loadScript(strSource) {
    return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.src = strSource;
        script.onload = () => resolve(script);
        script.onerror = () => reject(new Error(`Error loading script: "${strSource}"`));
        document.body.appendChild(script);
    });
}

(async () => {
    const objManifest = await getManifest();
    const arrScriptSources = filterManifest(Object.values(objManifest[''].js));
    for (const strSource of arrScriptSources) {
        try {
            await loadScript(strSource);
        } catch (err) {
            console.error(err);
        }
    }

    window.g2Init?.();
})();
